/**
 * Used with conditional logic in metabox.io field builders where a boolean can be passed and then used to compare
 * against this callback to either display or not display the field.
 * @returns {boolean}
 */
window.this_is_true = function () {
    return true;
}

/**
 * Restrict parent level blocks to fc-section and allow all allowed blocks as children of fc-section
 *
 * @param settings
 * @param name
 * @returns {*}
 */
const restrictEditorParentBlocks = (settings, name) => {
    // console.log(settings);
    // console.log(name)

    const restrictedBlocks = [
        'core/archives',
        'core/audio',
        'core/avatar',
        'core/calendar',
        'core/categories',
        'core/classic',
        'core/code',
        'core/columns',
        'core/comments',
        'core/cover',
        'core/details',
        'core/embed',
        'core/file',
        'core/footnotes',
        'core/freeform',
        'core/gallery',
        'core/group',
        'core/heading',
        'core/html',
        'core/image',
        'core/latest-comments',
        'core/latest-posts',
        'core/list',
        'core/loginout',
        'core/media-text',
        'core/more',
        'core/navigation',
        'core/nextpage',
        'core/page-list',
        'core/paragraph',
        'core/post-author',
        'core/post-author-biography',
        'core/post-author-name',
        'core/post-comments-form',
        'core/post-content',
        'core/post-date',
        'core/post-excerpt',
        'core/post-featured-image',
        'core/post-navigation-link',
        'core/post-terms',
        'core/post-title',
        'core/preformatted',
        'core/pullquote',
        'core/query',
        'core/query-pagination',
        'core/query-title',
        'core/quote',
        'core/read-more',
        'core/rss',
        'core/search',
        'core/separator',
        'core/shortcode',
        'core/site-logo',
        'core/site-title',
        'core/site-tagline',
        'core/social-links',
        'core/spacer',
        'core/table',
        'core/tag-cloud',
        'core/taxonomies',
        'core/term-description',
        'core/verse',
        'core/video',
        'core/widget-group',
        'fc-blocks/accordion',
        'fc-blocks/alignment-box',
        'fc-blocks/book-list',
        'fc-blocks/button',
        'fc-blocks/carousel',
        'fc-blocks/cards-row',
        'fc-blocks/content-container',
        'fc-blocks/custom-table',
        'fc-blocks/file',
        'fc-blocks/heading',
        'fc-blocks/image',
        'fc-blocks/nav-list',
        // 'fc-blocks/page-banner',
        'fc-blocks/position-box',
        // 'fc-blocks/section',
        'fc-blocks/recent-posts',
        'fc-blocks/sub-title',
        'fc-blocks/svg',
        'fc-blocks/testimonials',
        'gravityforms/form',

        // Metabox.io
        'meta-box/login-form',
        'meta-box/profile-form',
        'meta-box/registration-form',
        'meta-box/submission-form',
        'meta-box/user-dashboard',

        // Rank Math
        'rank-math/command', // AI Content Assist
        'rank-math/submission-form', // Child block ???
        'rank-math/toc-block',
        'rank-math/faq-block',
        'rank-math/howto-block',
        'rank-math/rich-snippet', // schema

        // Yoast
        'yoast/how-to-block',
        'yoast/faq-block',
        'yoast-seo/breadcrumbs'
    ];

    if (restrictedBlocks.includes(name)) {
        settings.parent = [
            'fc-blocks/section',
            'core/group',
            'core/columns',
            'core/column',
            'fc-blocks/accordion-item',
            'fc-blocks/alignment-box',
            'fc-blocks/content-container',
            'fc-blocks/carousel-item',
            'fc-blocks/position-box',
            'fc-blocks/page-banner',
            'fc-blocks/widget-group'
        ]
    }
    return settings
}

wp.hooks.addFilter(
    'blocks.registerBlockType',
    'fc/restrict-editor-parent-blocks',
    restrictEditorParentBlocks
);

/**
 * Remove all embed blocks except those flagged.
 * Reference: https://wordpress.stackexchange.com/questions/326959/gutenberg-disallow-certain-custom-blocks-but-keep-all-core-blocks
 */
if(wp.hasOwnProperty('domReady')) {
    wp.domReady(function () {
        const allowedEmbedBlocks = [
            'youtube',
        ];

        if (wp.hasOwnProperty('blocks')) {
            wp.blocks.getBlockType('core/embed').variations.forEach(function (blockVariation) {
                if (
                    allowedEmbedBlocks.indexOf(blockVariation.name) === -1
                ) {
                    wp.blocks.unregisterBlockVariation('core/embed', blockVariation.name);
                }
            });
        }
    });
}


function hasExpandedState(toggleElement) {
    return (String(toggleElement.getAttribute('aria-expanded')).toLowerCase() === 'true');
}

function handleLeftSidebarState(leftSidebarToggle, popoverType) {
    setTimeout(() => {

        let popoverBlock = document.querySelector('.components-popover.' + popoverType);

        if(popoverBlock) {
            if(hasExpandedState(leftSidebarToggle)) {
                popoverBlock.classList.add('left-sidebar-open');
            } else {
                popoverBlock.classList.remove('left-sidebar-open');
            }
        }
    }, 20);
}
function handleRightSidebarState(rightSidebarToggle, popoverType) {
    setTimeout(() => {

        let popoverBlock = document.querySelector('.components-popover.' + popoverType);

        if(popoverBlock) {
            if(hasExpandedState(rightSidebarToggle)) {
                popoverBlock.classList.add('right-sidebar-open');
            } else {
                popoverBlock.classList.remove('right-sidebar-open');
            }
        }
    }, 20);
}

/**
 * Handle actions related to a full-screen popover control
 */
window.addEventListener("load", (event) => {

    const blockEditor = document.querySelector('body.block-editor-page');
    if(blockEditor) {
        const leftSidebarToggle = document.querySelector('.editor-document-tools__inserter-toggle');
        const rightSidebarToggle = document.querySelector('.edit-post-header .edit-post-header__settings button[aria-label="Settings"]');
        // const openChildBlockEditorBtn = document.querySelector('.open-child-block-editor');
        // Would like to have it open with right sidebar space preserved if open.

        blockEditor.addEventListener('click', function(event){
            handleLeftSidebarState(leftSidebarToggle, 'child-block-editor');
            handleRightSidebarState(rightSidebarToggle, 'child-block-editor');
        });
    }
});

